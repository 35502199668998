<template>
  <div class="honor-wrapper">
    <common-title title="产品定义"></common-title>
    <div class="bg-wrapper">
      <h1 class="name">大大买钢网</h1>
      <p>
        通过新一代信息技术手段，为钢铁供应链企业提供云化服务，实现钢铁产业的数字化转型升级。
      </p>
    </div>
    <common-title title="产品说明"></common-title>
    <div class="dec-wrapper">
      <div class="dec-item">
        <img src="../../assets/img/about_honor_1.png" alt="大大买钢" />
        <div class="title">交易</div>
        <div class="text">
          交易型SAAS逐步引导在线客户<br />和在线商品智能撮合商城
        </div>
      </div>
      <div class="dec-item">
        <img src="../../assets/img/about_honor_2.png" alt="大大买钢" />
        <div class="title">交付</div>
        <div class="text">通过技术产品和物流产品降本<br />增效、提升体验</div>
      </div>
      <div class="dec-item">
        <img src="../../assets/img/about_honor_3.png" style="height:63px;" alt="大大买钢" />
        <div class="title">金融</div>
        <div class="text">
          多银行多账户体系下的大额移<br />动支付、无抵押自融资
        </div>
      </div>
    </div>
    <common-title title="产品矩阵"></common-title>
    <div class="product-wrapper">
        <!--   -->
      <div class="accordion-box">
        <div class="accordion" v-for="(item,index) in datas" :key="index" :class="'bg'+(index+1)">
          <div class="mark-wrapper" :class="item.hover?'bgColor':''" @mouseenter="item.hover = true" @mouseleave="item.hover = false">
              <img src="../../assets/img/about_honor_icon_1.png" alt="大大买钢" style="width:85px;height:84px" v-if="index == 0">
                <img src="../../assets/img/about_honor_icon_2.png" alt="大大买钢" style="width:85px;height:84px;" v-if="index == 1">
                <img src="../../assets/img/about_honor_icon_3.png" alt="大大买钢" style="width:85px;height:84px;" v-if="index == 2">
                <img src="../../assets/img/about_honor_icon_4.png" alt="大大买钢" style="width:85px;height:84px;" v-if="index == 3">
                <img src="../../assets/img/about_honor_icon_5.png" alt="大大买钢" style="width:85px;height:84px;" v-if="index == 4">
                <div class="title">{{item.title}}</div>
                <p class="line"></p>
                <div class="dec-box-wrapper"  v-show="item.hover">
                    <p>{{item.dec}}</p>
                    <p class="subTitle">相关产品</p>
                    <div class="tag-box"> <span class="tag" v-for="(it,index2) in item.items" :key="index2">{{it}}</span></div>
                </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonTitle from "./components/common-title.vue";
export default {
  name: "",
  components: { commonTitle },
  data() {
    return {
        datas:[
            {title:'Saas',hover:false,dec:'通过传统对ERP的改造，提供采购、销售、库存、财务、金融、决策分析等功能，实现交易替代管理，是钢贸业务经营和管理决策的好帮手。',items:['大大买钢云服务平台','汉河版']},
            {title:'物流',hover:false,dec:'整合运输相关资源，打破壁垒，将托运方、承运方、司机等无缝衔接，为用户提供撮合交易服务。',items:['孺子牛'] },
            {title:'金融',hover:false,dec:'用户在SAAS平台沉淀业务数据，平台通过业务流、资金流、发票流、物流的“四流合一“审核数据的真实性，对接渠道资金。为平台用户提供金融服务，通过客户数据实现分析，并提供授信服务。',items:['大大金服','钱会多']},
            {title:'资金支付',hover:false,dec:'整合运输相关资源，打破壁垒，将托运方、承运方、司机等无缝衔接，为用户提供撮合交易服务。',items:['大大买钢云服务平台','汉河版']},
            {title:'数据',hover:false,dec:'平台沉淀业务数据，构建价格指数，形成在线价格，并逐步实现在线商品、在线客户和实时行业信息。',items:['智会多','鲸GO小程序','钢铁云APP','EOC旗舰版 ']}
        ]
    };
  },
};
</script>
<style lang='scss' scoped>
.honor-wrapper {
  width: 100%;
  .bg-wrapper {
    width: 100%;
    height: 290px;
    background: url(../../assets/img/about_honor.png) no-repeat;
    background-size: 100% 100%;
    color: #fff;
    text-align: center;
    .name {
      padding-top: 76px;
      margin-bottom: 44px;
    }
  }
  .dec-wrapper {
    width: 1200px;
    height: 380px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dec-item {
      width: 366px;
      height: 219px;
      background-color: #ffffff;
      box-shadow: 0px 0px 12px rgba(220, 59, 76, 0.06);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 74px;
        height: 73px;
      }
      .title {
        margin-top: 16px;
        margin-bottom: 10px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #333333;
        font-size: 18px;
      }
      .text {
        font-family: PingFang SC;
        color: #666666;
        font-size: 14px;
        line-height: 28px;
        text-align: center;
      }
    }
    .dec-item:hover {
      box-shadow: 0px 0px 12px rgba(220, 59, 76, 0.16);
    }
  }
  .product-wrapper {
    width: 100%;
    height: 627px;
    background: url(../../assets/img/about_culture_bg_2.png) no-repeat;
    background-size: 100% 100%;
    display: flex;

    .accordion-box {
    //   width: 1200px;
      height: 627px;
      margin: 0 auto;
      overflow: hidden;
    }
    .accordion-box div {
      width: 225px;
      float: left;
      transition: all 1s;
      height: 100%;
    }
    .accordion{
        width: 100%;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .mark-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 0 auto;
            .title{
            width: 100%;
            font-size: 32px;
            color: #ffffff;
            margin-top:55px;
            height: 32px;
            text-align: center;
        }
        .line{
            width: 70px;
            height: 30px;
            border-bottom:1px solid rgba(177, 165, 159, 1);
        }
        .dec-box-wrapper{
            width: 238px;
            height: 220px;
            margin: 0 auto;
            color: #fff;
            font-size: 14px;
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .subTitle{
                width: 100%;
                margin-top: 30px;
                margin-bottom: 10px;
                text-align: left;
            }
            .tag-box{
                width: 100%;
            }
            .tag{
                display: inline-block;
                margin-right: 10px;
                margin-bottom: 10px;
                padding: 2px 10px;
                background: rgba(199, 197, 197,0.48);
            }
            .tag:last-child{
                margin-right: 0px;
            }
        }
        }
        .bgColor{
            background: #0d0d0c8c;
        }

    }

     .accordion-box .bg1{
        background: url(../../assets/img/about_culture_box_1.png) no-repeat;
        background-size: 100% 100%;
    }
    .accordion-box .bg2{
        background: url(../../assets/img/about_culture_box_2.png) no-repeat;
        background-size: 100% 100%;
    }
    .accordion-box .bg3{
        background: url(../../assets/img/about_culture_box_3.png) no-repeat;
        background-size: 100% 100%;
    }
    .accordion-box .bg4{
        background: url(../../assets/img/about_culture_box_4.png) no-repeat;
        background-size: 100% 100%;
    }
    .accordion-box .bg5{
        background: url(../../assets/img/about_culture_box_5.png) no-repeat;
        background-size: 100% 100%;
    }
    .accordion-box:hover div {
      /*width: 225px;*/
      /*.mark-wrapper{*/
      /*  width: 300px;*/

      /*}*/
    }
    .accordion-box .accordion:hover {
      width: 300px;
      .mark-wrapper{
         width: 300px;
      }

    }


  }
}
</style>
