<template>
  <div class="culture-wrapper">
    <common-title title="领导团队"></common-title>
    <div class="person-list">
      <div class="person-item left" v-for="(item, index) in datas" :key="index">
        <div class="flex left" v-if="index % 2 == 0">
          <img :src="item.url" alt="大大买钢" class="person-left" />
          <div class="person-right">
            <div class="name">{{ item.name }}</div>
            <div class="job">{{ item.job }}</div>
            <div class="dec">{{ item.dec }}</div>
          </div>
        </div>
        <div class="flex right" v-else>
          <div class="person-left">
            <div class="name">{{ item.name }}</div>
            <div class="job">{{ item.job }}</div>
            <div class="dec">{{ item.dec }}</div>
          </div>
          <img :src="item.url"  alt="大大买钢" class="person-right" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonTitle from "./components/common-title.vue";
export default {
  name: "",
  components: { commonTitle },
  data() {
    return {
      datas: [
        {
          url: "https://ddmallimg.ddmg.com/public/assets/img/index/about_us/fsl.png",
          name: "傅胜龙",
          job: "董事长 大汉集团总裁",
          dec: "清华大学EMBA，中南大学商学院博士。湖南省第十二届人大代表，荣誉有优秀中国特色社会主义事业建设 者、中国企业改革突出贡献奖、中国城市建设60年十大杰出人物，全国五一劳动模范等。二十余年钢铁产业链从业经验，钢贸权威专家，中国民营钢贸第一人。",
        },
        {
          url: "https://ddmallimg.ddmg.com/public/assets/img/index/about_us/hjs.png",
          name: "贺金生",
          job: "大汉电子商务有限公司总裁",
          dec: "湖南大学EMBA，全国物流行业劳动模范,20多年钢铁流通经验。曾任5A级物流企业总经理,带领团队连续5年获全国民营钢贸第一名，在钢铁产业拥有较大的影响力。",
        },
        {
          url: "https://ddmallimg.ddmg.com/public/assets/img/index/about_us/wsh.png",
          name: "万世红",
          job: "大汉电子商务有限公司CEO",
          dec: "曾任集团董事长，国有大中型企业副总裁，多家企业总经理，是供应链金融资深专家。",
        },
        {
          url: "https://ddmallimg.ddmg.com/public/assets/img/index/about_us/hxr.png",
          name: "贺先仁",
          job: "大汉电子商务有限公司副总裁",
          dec: "中南大学硕士，经济师，从事钢贸行业二十余年，拥有丰富的行业资源和敏锐的行情判断力。先后担任中国物流与采购联合会常务理事、中国金属材料流通协会副会长、湖南省物流与采购联合会副会长、湖南省金属材料商会副会长等职务。",
        },
        {
          url: "https://ddmallimg.ddmg.com/public/assets/img/index/about_us/wy.png",
          name: "王钰",
          job: "大汉电子商务有限公司CTO",
          dec: "曾担任钢铁行业知名软件公司技术总监，任职期间为数十家大型钢企提供流程优化和再造服务，并曾担任多个电商平台项目的项目经理。十多年钢铁行业管理系统和大宗商品电商平台软件项目管理咨询经验。",
        },
      ],
    };
  },
};
</script>
<style lang='scss' scoped>
.culture-wrapper {
  width: 1200px;
  margin: 0 auto;
  .person-list {
    width: 100%;
    .person-item {
      width: 100%;
      margin-bottom: 92px;
    }
    .flex {
      display: flex;
      align-items: center;
      width: 100%;
      .name {
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        font-size: 28px;
        height: 40px;
        line-height: 40px;
      }
      .job {
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        font-size: 18px;
        height: 25px;
        line-height: 25px;
      }
      .dec{
          border-top:1px solid #eaeaea;
          margin-top: 12px;
          padding-top: 20px;
          font-family:PingFang SC;
            color:#333333;
            font-size:14px;
            line-height:24px;
      }
    }
    .left {
      .person-left {
        width: 300px;
        height: 200px;
        margin-right: 20px;
      }
      .person-right {
        width: 778px;
      }
    }
    .right {
      .person-left {
        width: 778px;
      }
      .person-right {
        width: 300px;
        height: 200px;
      }
    }
  }
}
</style>
