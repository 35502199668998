<template>
  <div class="aboutus_wrapper">
      <commonHead></commonHead>
      <!-- titile -->
      <div class="title_wrapper">
          <router-link to="/index">
              <img src="../../assets/img/3367.svg" alt="大大买钢" class="logo_img">
          </router-link>
          <div class="tab_wrapper">
              <div class="tab_item" @click="cur = index" :class="cur == index?'tab_item_active':''" v-for="(item,index) in tabs" :key="index">{{item}}</div>
          </div>
      </div>
      <el-carousel
           :interval="3000"
           arrow="hover"
           autoplay
           style="width: 100%; height:360px; border-radius: 8px"
      >
          <el-carousel-item style="width: 100%; height:360px">
              <img src="../../assets/img/about_bg1.jpg" alt="大大买钢" class="title_bg_img">
          </el-carousel-item>
          <el-carousel-item style="width: 100%; height:360px">
              <img src="../../assets/img/about_bg2.jpg" alt="大大买钢" class="title_bg_img">
          </el-carousel-item>
      </el-carousel>

      <companyIntroduct v-if="cur==0"></companyIntroduct>
      <productIntroduct v-else-if="cur==1"></productIntroduct>
      <teamLeader v-else-if="cur==2"></teamLeader>
      <corporateCulture v-else-if="cur==3"></corporateCulture>
      <honor v-else-if="cur==4"></honor>
      <footerNav></footerNav>
  </div>
</template>
<script>
import companyIntroduct from './companyIntroduct.vue'
import productIntroduct from './productIntroduct.vue'
import teamLeader from './teamLeader.vue'
import corporateCulture from './corporateCulture.vue'
import honor from './honor.vue'
import commonHead from '../../components/headerMark.vue'
import footerNav from '../../components/footerNav.vue'
export default {
  name: "",
  components:{ commonHead,footerNav,companyIntroduct,productIntroduct,teamLeader,corporateCulture,honor },
  data() {
    return {
        tabs:['公司介绍','产品介绍','团队指导','企业文化','荣誉资质'],
        cur:0,
    };
  },
};
</script>
<style lang='scss' scoped>
.aboutus_wrapper{
    width: 100%;
    .title_wrapper{
        width: 1200px;
        margin: 0 auto;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo_img{
            width: 154px;
            height: 46px;
        }
        .tab_wrapper{
            width: 610px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .tab_item{
                font-size:15px;
                user-select: none;
                cursor:pointer;
                color: #333333;
            }
            .tab_item_active{
                color: #ef2147;
            }
        }

    }
    .title_bg_img{
        width: 100%;
        height: 100%;
    }
}
</style>
