<template>
  <div class="honor-wrapper">
    <common-title title="荣誉证书"></common-title>
    <div class="banner">
        <div class="img-icon" @click="arrowClick('left')"><img src="../../assets/img/about_arrow_left.png" alt="大大买钢"></div>
        <el-carousel ref="cardShow" :autoplay="false" indicator-position="none" class="banner-box" arrow="never">
            <el-carousel-item v-for="(item,index) in imgs_arr" :key="index" >
                <img :src="item2.url"  v-for="(item2,index2) in item" :key="index2" alt="大大买钢">
            </el-carousel-item>
        </el-carousel>
        <div class="img-icon" @click="arrowClick('right')"><img src="../../assets/img/about_arrow_right.png" alt="大大买钢"></div>
    </div>

    <common-title title="公司资质"></common-title>
    <div class="wrapper">
        <div class="item-wrapper" v-for="(item,index) in datas" :key="index">
            <img :src="item.url" alt="大大买钢"  class="item-img">
            <p>《{{item.name}}》</p>
        </div>
    </div>
    <div class="bottom">
        <div class="line"></div>
        <div class="text">累计取得知识产权35件</div>
        <div class="line"></div>
    </div>
  </div>
</template>
<script>
import commonTitle from "./components/common-title.vue";
export default {
  name: "",
  components: { commonTitle },
  data() {
    return {
       datas:[
           {name:'EOC小程序',url:"https://ddmallimg.ddmg.com/public/assets/img/index/about_us/certificate1.png"},
           {name:'EOC云平台',url:"https://ddmallimg.ddmg.com/public/assets/img/index/about_us/certificate2.png"},
           {name:'大大金条企业保理融',url:"https://ddmallimg.ddmg.com/public/assets/img/index/about_us/certificate3.png"},
           {name:'大大金条企业采钢宝',url:"https://ddmallimg.ddmg.com/public/assets/img/index/about_us/certificate4.png"},
           {name:'大大开票',url:"https://ddmallimg.ddmg.com/public/assets/img/index/about_us/certificate5.png"},
           {name:'好会付',url:"https://ddmallimg.ddmg.com/public/assets/img/index/about_us/certificate6.png"},
           {name:'钱会多',url:"https://ddmallimg.ddmg.com/public/assets/img/index/about_us/certificate7.png"},
           {name:'孺子牛运营后台',url:"https://ddmallimg.ddmg.com/public/assets/img/index/about_us/certificate8.png"},
       ],
       imgs:[
           {name:'中国B2B企业百强',url:'https://ddmallimg.ddmg.com/public/assets/img/index/about_us/p1.jpg'},
           {name:'2016年中国互联网百强企业',url:'https://ddmallimg.ddmg.com/public/assets/img/index/about_us/p2.jpg'},
           {name:'湖南省移动互联网重点企业',url:'https://ddmallimg.ddmg.com/public/assets/img/index/about_us/p3.jpg'},
           {name:'中国电子商务协会会员单位',url:'https://ddmallimg.ddmg.com/public/assets/img/index/about_us/p4.jpg'},
           {name:'中国互联网诚信示范企业',url:'https://ddmallimg.ddmg.com/public/assets/img/index/about_us/p5.jpg'},
           {name:'中国互联网金融协会会员单位',url:'https://ddmallimg.ddmg.com/public/assets/img/index/about_us/p6.jpg'},
           {name:'中国金属材料流通协会副会长单位',url:'https://ddmallimg.ddmg.com/public/assets/img/index/about_us/p7.jpg'},
           {name:'中国互联网优秀企业',url:'https://ddmallimg.ddmg.com/public/assets/img/index/about_us/p8.jpg'},
           {name:'湖南省移动互联网重点企业',url:'https://ddmallimg.ddmg.com/public/assets/img/index/about_us/p9.jpg'},
           {name:'高新技术企业',url:'https://ddmallimg.ddmg.com/public/assets/img/index/about_us/p10.jpg'},
           {name:'5A企业',url:'https://ddmallimg.ddmg.com/public/assets/img/index/about_us/p11.jpg'},
           {name:'副会长单位',url:'https://ddmallimg.ddmg.com/public/assets/img/index/about_us/p12.jpg'},
       ],
       imgs_arr:[],
    };
  },
  mounted(){
      this.imgs_arr = this.getNewArray(this.imgs,4);
      console.log(this.imgs_arr);
  },
  methods:{
      // 平均分割数组
    getNewArray(arr, size){  // size=5，要分割的长度
        const arrNum = Math.ceil(arr.length/size, 10); // Math.ceil()向上取整的方法，用来计算拆分后数组的长度
        let index = 0; // 定义初始索引
        let resIndex = 0; // 用来保存每次拆分的长度
        const result = [];
        while(index< arrNum){
            result[index]= arr.slice(resIndex,size+resIndex);
            resIndex += size;
            index++;
        }
        return result;
    },
    arrowClick(val) {
      if(val === 'right') {
        this.$refs.cardShow.next()
      } else {
        this.$refs.cardShow.prev()
      }
    }
  }
};
</script>
<style lang='scss' scoped>
.honor-wrapper {
  width: 100%;
  .wrapper{
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item-wrapper{
          margin-bottom: 42px;
      }
      .item-img{
          width: 288px;
          height: 404px;
      }
      p{
          font-size: 14px;
          color: #333333;
          text-align: center;
      }
  }
  .bottom{
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      .line{
          width: 57px;
          height: 1px;
          background: #dddddd;
      }
      font-size: 14px;
      color: #666666;
      .text{
          margin: 0 10px;
      }
  }
  .banner{
      width: 1203px;
      margin: 0 auto;
      display: flex;

      .img-icon{
          width: 54px;
          height: 300px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          img{
              width: 10px;
              height: 15px;
          }
      }
      .banner-box{
          width: 100%;
        //   padding: 0 54px;
        .el-carousel__item{
            // padding: 0 54px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
          img{
              width: 255px;
              height: 170px;
          }
      }
  }

}
</style>
