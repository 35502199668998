<!--
 * @Author: luoyan
 * @Date: 2021-10-29 15:21:18
 * @LastEditTime: 2021-11-16 14:18:17
 * @Description: file content  底部栏目
-->
<template>
  <div class="footer">
    <div class="footer-nav">
      <div class="footer-nav-link">
        <div class="nav-link-title">友情链接:</div>
        <ul>
          <li><a href="https://www.dahangroup.com/" style="color: #7f7f7f;">大汉控股集团有限公司</a></li>
          <li><a href="https://rzn.ddmg.com/" style="color: #7f7f7f;">孺子牛</a></li>
          <li><a href="https://hhf.ddmg.com/" style="color: #7f7f7f;">好会付</a></li>
        </ul>
      </div>
      <div class="footer-nav-list">
        <div class="nav-list-contcat">
          <div class="list-contcat-img">
            <img src="../assets/img/phone.svg" alt="大大买钢" />
          </div>
          <div class="list-contcat-dianhua">
            <div>客服热线：</div>
            <div>400-888-2566</div>
            <div>接听时间:周一到周六 08:00-17:30</div>
          </div>
        </div>
        <div class="nav-list-item">
          <div class="list-item-title">客户第一</div>
          <div class="list-item-nvadetail"><a href="https://help.ddmg.com/index.php?s=/4&amp;page_id=336" target="_blank">买家指南</a></div>
          <div class="list-item-nvadetail"><a href="https://help.ddmg.com/index.php?s=/4&amp;page_id=337" target="_blank">卖家指南</a></div>
          <div class="list-item-nvadetail"><a href="https://help.ddmg.com/index.php?s=/4&amp;page_id=356" target="_blank">帮助中心</a></div>
        </div>
        <div class="nav-list-item">
          <div class="list-item-title">优质服务</div>
          <div class="list-item-nvadetail"><a href="/mall" target="_blank">大大商城</a></div>
          <div class="list-item-nvadetail"><a href="/supplyChain/index" target="_blank">供应链服务</a></div>
        </div>
        <div class="nav-list-item">
          <div class="list-item-title">平台声明</div>
          <div class="list-item-nvadetail"><a href="https://help.ddmg.com/index.php?s=/4&amp;page_id=338" target="_blank">法律声明</a></div>
          <div class="list-item-nvadetail"><a href="https://help.ddmg.com/index.php?s=/4&amp;page_id=339">服务总则</a></div>
          <div class="list-item-nvadetail"><a href="https://help.ddmg.com/index.php?s=/4&amp;page_id=340">注册协议</a></div>
          <div class="list-item-nvadetail"><a href="https://help.ddmg.com/index.php?s=/4&amp;page_id=341" target="_blank">大大商城规则</a></div>
        </div>

        <div class="nav-list-wx">
          <div class="list-item-title">关注我们</div>
          <div class="list-item-nvadetail-img"><img src="../assets/img/wx.svg" alt="大大买钢"></div>
        </div>
      </div>
      <div class="footer-nav-text">
        <div class="Homefootbody">
          <div style="margin: 5px 0px">大汉电子商务有限公司 版权所有 Copyright©大大买钢 2014-2021</div>
          <div style="margin: 5px 0px"> 增值电信业务经营许可证 湘B2-20150061 <a target="_blank" href="http://beian.miit.gov.cn" style="color:#a4aab4;">湘ICP备15003105号</a></div>
          <div style="color: #ffffff;margin: 5px 0px">客服:400-888-2566 不良和违法信息举报电话:0731-81801099 （5*8工作日接听）</div>

          <div class="tips">
            <a id="_xinchacharenzheng_cert_vip_chengxincn" style="text-decoration:none;" target="_blank" href="https://xyt.xinchacha.com/pcinfo?sn=437540065443450880&amp;certType=4">
              <img src="https://ddmallimg.ddmg.com/public/assets/img/indexbottom_12.png" alt="大大买钢" border="0" style="width: 114px;height: 41px;">
            </a>
            <a id="_xinchacharenzheng_cert_vip_kexinweb" style="text-decoration:none;" target="_blank" href="https://xyt.xinchacha.com/getpcInfo?sn=437810092243554304&amp;certType=8">
              <img src="https://ddmallimg.ddmg.com/public/assets/img/indexbottom_01.png" alt="大大买钢" border="0" style="width: 114px;height: 41px;">
            </a>
            <a target="xinchacha" href="http://www.beian.gov.cn/portal/index">
              <img src="https://ddmallimg.ddmg.com/public/assets/img/indexbottom_20.png" alt="大大买钢" style="width: 119px;height: 41px;">
            </a>
            <a target="xinchacha" href="http://www.cyberpolice.cn/wfjb/">
              <img src="https://ddmallimg.ddmg.com/public/assets/img/indexbottom_21.png" alt="大大买钢" style="width: 83px;height: 41px;">
            </a>
            <a href="https://www.12377.cn/" target="_blank">
              <img src="https://ddmallimg.ddmg.com/public/assets/img/indexbottom_17.png" alt="大大买钢" border="0" style="width: 160px;height: 41px;">
            </a>
            <a href="https://beian.miit.gov.cn/state/outPortal/loginPortal.action" target="_blank">
              <img src="https://ddmallimg.ddmg.com/public/assets/img/indexbottom_18.png" alt="大大买钢" border="0" style="width: 200px;height: 40px;">
            </a>
          </div>
        </div>
<!--        <div class="nav-text-left"><img src="../assets/img/26.svg" alt="大大买钢" /></div>-->
<!--        <div class="nav-text-right">-->
<!--          <div>-->
<!--            Copyright@大大买钢 2014-2020, All Rights Reserved Tel:400-888-2566-->
<!--          </div>-->
<!--          <div>-->
<!--            大汉电子商务有限公司 版权所有 增值电信业务经营许可证-->
<!--            湘ICP备15003105号-1-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerNav",
  data() {
    return {};
  },
  methods: {},
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" >
.footer {
  width: 100%;
  height: 544px;
  background-color: #141414ff;
}
.footer a {
  text-decoration: none;
}
.footer-nav {
  max-width: 1200px;
  margin: 0 auto;
}
.footer-nav-link {
  display: flex;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  font-size: 14px;
  padding-top: 55.76px;
  margin-bottom: 36.84px;
}
.footer-nav-link ul {
  display: flex;
}
.footer-nav-link li {
  margin-right: 30px;

  list-style: none;
}
.footer-nav-link li a {
  color: #ffffff;
  opacity: 50%;
}
.nav-link-title {
  margin-right: 40px;
  opacity: 50%;
}
.footer-nav-list {
  display: flex;
  border-top: 1px solid #252525;
  border-bottom: 1px solid #252525;
  height: 248px;
  padding-top: 36.72px;
  justify-content: space-between;
}

.nav-list-contcat {
  display: flex;
}
.list-contcat-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  line-height: 50px;
  margin-right: 20px;
}
.list-contcat-img img {
  width: 37.63px;
}
.list-contcat-dianhua {
  color: #ffffff;
}
.list-contcat-dianhua div:nth-child(1) {
  font-family: MicrosoftYaHei;
  color: #ffffff;
  font-size: 16px;
  line-height: 28px;
}
.list-contcat-dianhua div:nth-child(2) {
  font-family: MicrosoftYaHei;
  color: #ffffff;
  font-size: 24px;
  line-height: 42px;
}
.list-contcat-dianhua div:nth-child(3) {
  opacity: 50%;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  font-size: 12px;
  line-height: 21px;
}
.list-item-title {
  font-family: MicrosoftYaHei;
  color: #ffffff;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 26px;
}
.nav-list-item a {
  font-family: MicrosoftYaHei;
  color: #7f7f7f;
  font-size: 14px;
  line-height: 32px;

}
.footer-nav-text{
  display: flex;

  justify-content: center;
  color:#ffffff;
  align-items: center;
}
.Homefootbody{
  width: 1200px;
  font-size: 14px;
  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
  color: #949494;
}
.tips{
  padding: 5px 0px;
}
.tips a{
  margin: 0px 10px;

}

/*.nav-text-left{*/
/*  margin-right: 15px;*/
/*}*/
/*.nav-text-left img{*/
/* vertical-align: middle;*/
/*}*/
/*.nav-text-right{*/
/*  font-family:MicrosoftYaHei;*/
/*  color:#ffffff;*/
/*  font-size:14px;*/
/*  line-height:28px;*/
/*  opacity:50%;*/
/*}*/

</style>
