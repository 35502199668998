<template>
  <div class="culture-wrapper">
    <div class="part_1">
      <common-title title="企业文化"></common-title>
      <div class="list">
        <div class="list-item" v-for="(item, index) in datas" :key="index">
          <img
            v-if="index == 0"
            src="../../assets/img/about_culture_0.png"
            alt="大大买钢"
          />
          <img
            v-if="index == 1"
            style="width: 51px; height: 69px"
            src="../../assets/img/about_culture_1.png"
            alt="大大买钢"
          />
          <img
            v-if="index == 2"
            src="../../assets/img/about_culture_2.png"
            alt="大大买钢"
          />
          <img
            v-if="index == 3"
            src="../../assets/img/about_culture_3.png"
            alt="大大买钢"
          />
          <img
            v-if="index == 4"
            src="../../assets/img/about_culture_4.png"
            alt="大大买钢"
          />
          <img
            v-if="index == 5"
            style="height: 50px"
            src="../../assets/img/about_culture_5.png"
            alt="大大买钢"
          />
          <div class="right">
            <div class="name">{{ item.name }}</div>
            <div class="dec">{{ item.dec }}</div>
          </div>
        </div>
      </div>

    </div>
    <!-- banner-->
    <div class="part_2">
        <common-title title="企业活动"></common-title>
        <div class="part_2_box">
            <swiper :options="swiperOption" ref="mySwiper">
                <swiper-slide v-for="(item,index) in imgs" :key="index" class="slide">
                    <img :src="item" style="width:830px;height:550px;cursor: pointer;" alt="大大买钢">
                </swiper-slide>

            </swiper>
        </div>

    </div>
    <div class="part_3">
        <img src="../../assets/img/about_culture_fly.png" alt="大大买钢" class="fly_img">
        <div class="flag_box">
            <img src="../../assets/img/about_txt.png" alt="大大买钢">
        </div>
    </div>
  </div>
</template>
<script>
import commonTitle from "./components/common-title.vue";
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import "swiper/dist/css/swiper.css";
export default {
  name: "",
  components: { commonTitle,swiper, swiperSlide },
  data() {
    return {
        imgs:[
           'https://ddmallimg.ddmg.com/images/activity/img1.jpg',
           'https://ddmallimg.ddmg.com/images/activity/img2.jpg',
           'https://ddmallimg.ddmg.com/images/activity/img3.jpg',
           'https://ddmallimg.ddmg.com/images/activity/img4.jpg',
           'https://ddmallimg.ddmg.com/images/activity/img5.jpg',
           'https://ddmallimg.ddmg.com/images/activity/img6.jpg',
           'https://ddmallimg.ddmg.com/images/activity/img7.jpg',
           'https://ddmallimg.ddmg.com/images/activity/img8.jpg',
           'https://ddmallimg.ddmg.com/images/activity/img9.jpg',
           'https://ddmallimg.ddmg.com/images/activity/img10.jpg',
           'https://ddmallimg.ddmg.com/images/activity/img11.jpg',
           'https://ddmallimg.ddmg.com/images/activity/img12.jpg',
       ],

       swiperOption: {
            loop: true,
            initialSlide:0,
            autoplay: false,
            effect: 'coverflow',
            // 显示分页
            pagination: {
                // el: ".swiper-pagination",
                // clickable: true //允许分页点击跳转
            },
            coverflowEffect: {
                slideShadows: false, // 页面阴影效果
                rotate: 0, // 旋转的角度
                stretch: 1000, // 拉伸   图片间左右的间距和密集度，越大靠得越近
                depth: 250, // 深度   切换图片间上下的间距和密集度，值越大z轴距离越远，看起来越小。
                modifier: 1, // 修正值 该值越大前面的效果越明显
            },
            preventClicksPropagation:true
      },
      datas: [
        {
          name: "意愿",
          dec: "打造最具有价值的钢铁生态服务平台",
        },
        {
          name: "追求使命",
          dec: "赋能新钢贸   服务新钢铁",
        },
        {
          name: "企业精神",
          dec: "致良知",
        },
        {
          name: "核心价值观",
          dec: "客户第一   信用第一",
        },
        {
          name: "经营理念",
          dec: "大众品牌 大众平台 共创共享 成就彼此",
        },
        {
          name: "工作理念",
          dec: "勇于创新 快速决策 持续学习 全力投入",
        },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted() {
    // current swiper instance
    // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
    console.log("this is current swiper instance object", this.swiper);
    // this.swiper.slideTo(3, 1000, false);
  }
};
</script>
<style lang='scss' scoped>
.culture-wrapper {
  width: 100%;
  .part_1 {
    width: 100%;
    height: 418px;
    background: #f8f8f8;
    position: relative;
    top: -5px;
    padding-top: 1px;
    .list {
      width: 1126px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .list-item {
        width: 358px;
        height: 105px;
        background-color: #ffffff;
        border-radius: 2px;
        box-shadow: 0px 0px 12px rgba(220, 59, 76, 0.06);
        margin: 13px 0;
        display: flex;
        align-items: center;
        img {
          width: 52px;
          height: 52px;
          margin-left: 24px;
          margin-right: 12px;
        }
        .name {
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          font-size: 18px;
          line-height: 28px;
        }
        .dec {
          font-family: PingFang SC;
          color: #666666;
          font-size: 14px;
          line-height: 28px;
        }
      }
    }
  }
  .part_2{
      width: 100%;
      height: 800px;
      background: url(../../assets/img/about_cuitrue_bg.png) no-repeat;
      background-size:100% 100%;
  }
  .part_2_box{
      width:1100px;
      position: relative;
      left: calc(50% - 500px);
      top:60px;

  }
  .part_3{
      width: 100%;
      height: 588px;
      background: url(../../assets/img/about_culture_bg_2.png) no-repeat;
      background-size:100% 100%;
      position: relative;
      .fly_img{
          width: 307px;
          height: 308px;
          position: absolute;
          z-index: 10;
          left: 148px;
          top:-162px
      }
      .flag_box{
          width: 1200px;
          height: 494px;
          position: relative;
          left: calc(50% - 600px);
          top: 47px;
          z-index: 22;
      }
  }
}
</style>
