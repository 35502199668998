<template>
  <div class="components-wrapper">
    <!-- 公司介绍 -->
    <div class="img-wrapper">
      <div class="img-box">
        <common-title title="公司介绍"></common-title>
        <div class="dec">
          &nbsp; &nbsp;&nbsp;
          &nbsp;大汉电子商务有限公司成立于2014年12月，注册资金5亿元人民币，是中国企业500强大汉控股集团有限公司的控股子公司。2016年被列入长沙市重点上市后备企业，旗下拥有44家子分公司，营销网点遍布湖南、湖北、江西、广东、广西、云南、贵州、四川、重庆、陕西、浙江、江苏等省市，年销售钢材超1200万吨，实现销售收入超540亿元。公司曾获得“中国钢铁流通企业5A级单位”，“2020湖南省高新技术企业”，“2021湖南省移动互联网重点企业”，“2020中国金属材料流通协会副会长单位”。
          <br />&nbsp; &nbsp;&nbsp;
          &nbsp;2020年7月大汉电子商务有限公司以生态平台的形式，整合旗下的区域平台、交易平台、物流平台、资讯平台。大汉电子商务有限公司依托大汉集团 30 多年的产业积淀，遵循共享经济原理，搭建“大大买钢”钢铁电商服务平台。“大大买钢”意为“大汉的品牌，大众的平台”，以传统钢贸企业和个人从业者为“碎片化服务”对象，提供“SAAS+服务”的用户价值，用“云+网+链”的方式重构钢铁产业价值链，致力打造最具价值的钢铁产业链生态服务平台。未来，大大买钢将进一步整合产业，打通上下游，为原燃料、钢厂、终端客户、钢贸商提供共生共享的数据服务，构建“平台+生态”的钢铁产业新格局。
        </div>
      </div>
    </div>
    <!-- 商业模式 -->
    <div class="business-wrapper">
      <common-title title="商业模式"></common-title>
      <img
        src="../../assets/img/about_1_busness.svg"
        alt="大大买钢"
        class="business-img"
      />
    </div>
    <!-- 发展历程 -->
    <div class="develep-wrapper">
      <common-title title="发展历程"></common-title>
      <div class="change_tab">
        <img src="../../assets/img/7.png" alt="大大买钢" class="img1" @click="reduce" />
        <span>{{ yearArr[curYear] }}</span>
        <img src="../../assets/img/7.png" alt="大大买钢" @click="addYear" />
      </div>
      <div class="container">
        <!-- 2014-2019 -->
        <div class="container_box"  v-if="curYear == 0">
          <commonLine typeIndex="1">
            <span slot="title">2014年12月</span>
            <span slot="dec">获得大汉集团3000万天使轮融资</span>
          </commonLine>
          <commonLine typeIndex="1">
            <span slot="title">2015年11月</span>
            <span slot="dec"
              >日销量突破 <strong>100000吨</strong>，月销售额超<strong
                >4亿元</strong
              ></span
            >
          </commonLine>
          <commonLine typeIndex="1">
            <span slot="title">2016年12月</span>
            <span slot="dec"
              >销售额超过<strong>40亿元</strong>，雄踞中西南钢铁之首</span
            >
          </commonLine>
          <commonLine typeIndex="1" :isEnd="true">
            <span slot="title">2017年12月</span>
            <span slot="dec">实现年度盈亏平衡</span>
          </commonLine>
          <commonLine typeIndex="2">
            <span slot="title">2015年8月</span>
            <span slot="dec">大大买钢网站上线运营</span>
          </commonLine>
          <commonLine typeIndex="2">
            <span slot="title">2016年3月</span>
            <span slot="dec">获得启赋资本5000万，pre-A轮融资</span>
          </commonLine>
          <commonLine typeIndex="2">
            <span slot="title">2017年3月</span>
            <span slot="dec">大大白条上线，逐步构建数据征信平台</span>
          </commonLine>
        </div>
        <!-- 2018 -->
        <div class="container_box" style="width: 1300px" v-if="curYear == 1">
          <commonLine typeIndex="1">
            <span slot="title">1月10日</span>
            <span slot="dec"
              >大大买钢发布“平台+公司”业务模式，平台用户从个人拓展到企业，深耕钢铁产业流通领域。</span
            >
          </commonLine>
          <commonLine typeIndex="1">
            <span slot="title">4月13日</span>
            <span slot="dec"
              >B2B内参主办的“2018第三届全国大宗商品电商峰会”在北京举行。大大买钢获评“2018年中国大宗商品电商百强企业”。</span
            >
          </commonLine>
          <commonLine typeIndex="1">
            <span slot="title">7月19日</span>
            <span slot="dec"
              >大大买钢荣获中国电子商务协会B2B行业分会评选出的“2018年上半年中国B2B行业百强榜”</span
            >
          </commonLine>
          <commonLine typeIndex="1">
            <span slot="title">12月14日</span>
            <span slot="dec"
              >2018中国产业互联与数字经济大会上，大大买钢进入中国产业互联网发展联盟发布的2018年度中国产业互联网TOP100排行榜单。</span
            >
          </commonLine>

          <commonLine typeIndex="2" :isEven="true">
            <span slot="title">3月18日</span>
            <span slot="dec"
              >大大买钢数据自融资产品大大金条上线。依托平台用户的业务数据沉淀，形成数据风控体系，构建数据转换信用、信用兑换资本的变现渠道。</span
            >
          </commonLine>
          <commonLine typeIndex="2" :isEven="true">
            <span slot="title">6月28日</span>
            <span slot="dec"
              >大汉电子商务有限公司荣获“2017年度中国钢铁电商最具影响力企业”，大大买钢CEO彭政军荣获“2017年度中国钢铁电商杰出人物”。</span
            >
          </commonLine>
          <commonLine typeIndex="2" :isEven="true">
            <span slot="title">8月31日</span>
            <span slot="dec"
              >在B2B内参、产业互联网头条和供应链金融头条联合主办的2018中国B2B供应链金融峰会上。大大买钢凭借创新的金融产品、独特的区块链应用荣获“2018中国B2B供应链金融创新大奖”。</span
            >
          </commonLine>
          <commonLine typeIndex="2" :isEven="true" :isEnd="true">
            <span slot="title">12月31日</span>
            <span slot="dec"
              >大大买钢平台交易额突破300亿元，交易量突破700万吨，再创历史新高</span
            >
          </commonLine>
        </div>

        <!-- 2019 -->
        <div class="container_box" style="width: 1771px" v-if="curYear == 2">
          <commonLine typeIndex="1">
            <span slot="title">2019年1月</span>
            <span slot="dec">获批三湘银行2亿授信，实现首次放款。</span>
          </commonLine>
          <commonLine typeIndex="1">
            <span slot="title">2019年4月</span>
            <span slot="dec"
              >大大买钢再创新高，平台单日交易量突破10万吨。平台用户也保持每日新增，持续扩大的态势。</span
            >
          </commonLine>
          <commonLine typeIndex="1">
            <span slot="title">2019年6月</span>
            <span slot="dec"
              >大汉电子商务有限公司与联易融正式签订项目合作协议。基于联易融蜂控平台，构建供应链金融的风控服务体系，打通实时发票采集功能。</span
            >
          </commonLine>
          <commonLine typeIndex="1">
            <span slot="title">2019年8月</span>
            <span slot="dec">大大买钢拍卖的第一笔资产--南通三建商票，已在南金中心完成交易，此拍卖亦意味着大大买钢实现了首笔无大股东担保融资。</span>
          </commonLine>
          <commonLine typeIndex="1">
            <span slot="title">2019年10月</span>
            <span slot="dec">2019年10月平台2019年累计交易量突破<strong>1000万吨</strong>。
              大汉电子商务有限公司入选2019年湖南省互联网企业50强榜单。
              新一代基于动态策略的数字供应链金融风控产品—信会多上线运行，其模型在《中国贸易金融》杂志发表。</span
            >
          </commonLine>
          <commonLine typeIndex="1" :isEnd="true">
            <span slot="title">2019年12月</span>
            <span slot="dec"
              >平台2019年累计交易额突破  450亿元。“孺子牛”新运力平台研发上线，7-12月累计运输量突破60万吨。</span
            >
          </commonLine>

          <commonLine typeIndex="2" >
            <span slot="title">2019年3月</span>
            <span slot="dec"
              >获批中信银行1.14亿授信。</span
            >
          </commonLine>
          <commonLine typeIndex="2" >
            <span slot="title">2019年5月</span>
            <span slot="dec"
              >获批三湘银行新增3亿授信，三湘银行总授信规模达5亿。</span
            >
          </commonLine>
          <commonLine typeIndex="2" >
            <span slot="title">2019年7月</span>
            <span slot="dec"
              >成立湖南汉牛物流科技有限公司，“孺子牛”运力平台上线。</span
            >
          </commonLine>
          <commonLine typeIndex="2" >
            <span slot="title">2019年9月</span>
            <span slot="dec"
              >三湘银行党委书记、行长夏博辉一行莅临大汉集团考察，在大汉金桥华美达酒店举行座谈会，双方签署了10亿元的战略合作协议，将用于支持供应链金融业务发展。</span
            >
          </commonLine>
          <commonLine typeIndex="2" >
            <span slot="title">2019年11月</span>
            <span slot="dec"
              >中信银行长沙分行上线湖南省首个“票付通”产品，并顺利通过上海票据交易所验收。大大买钢成为湖南省第一家接入上海票据交易所、实现票据线上支付的平台。</span
            >
          </commonLine>

        </div>
        <!-- 2020 -->
        <div class="container_box" style="width: 1483px" v-if="curYear == 3">
            <commonLine typeIndex="1">
                <span slot="title">2020年3月</span>
                <span slot="dec">集采平台面向终端客户，涵盖询价系统、竞价系统、招标系统、线上评标、商城直购等功能，通过聚合资源能力的提升发挥规模采购优势，为企业提供综合全面的采购服务。</span>
            </commonLine>
            <commonLine typeIndex="1">
                <span slot="title">2020年5月</span>
                <span slot="dec">大汉电子商务有限公司与联易融正式签订项目合作协议。基于联易融蜂控平台，构建供应链金融的风控服务体系，打通实时发票采集功能。</span>
            </commonLine>
            <commonLine typeIndex="1">
                <span slot="title">2020年8月</span>
                <span slot="dec">大汉电子商务有限公司与联易融正式签订项目合作协议。基于联易融蜂控平台，构建供应链金融的风控服务体系，打通实时发票采集功能。</span>
            </commonLine>
            <commonLine typeIndex="1">
                <span slot="title">2020年10月</span>
                <span slot="dec">大汉电子商务有限公司与联易融正式签订项目合作协议。基于联易融蜂控平台，构建供应链金融的风控服务体系，打通实时发票采集功能。</span>
            </commonLine>
            <commonLine typeIndex="1" :isEnd="true">
                <span slot="title">2020年12月</span>
                <span slot="dec">大汉电子商务有限公司与联易融正式签订项目合作协议。基于联易融蜂控平台，构建供应链金融的风控服务体系，打通实时发票采集功能。</span>
            </commonLine>

            <commonLine typeIndex="2">
                <span slot="title">2020年3月</span>
                <span slot="dec">大大金条，线上随借随还正式开通。</span>
            </commonLine>
            <commonLine typeIndex="2">
                <span slot="title">2020年7月</span>
                <span slot="dec">大汉电商与大汉供应链合并。大大赊呗正式上线，客户通过实名、AI人工智能刷脸认证签署个人担保，完成全线上下单、签约，通过订单在线构建数据及信用体系，建立动态测评及预警模型，有效评估风险。搭建了数据分析体系，定期出具数据分析报告，月度董事会报告，季度经营分析报告，为管理者提供决策赋能。</span>
            </commonLine>
            <commonLine typeIndex="2">
                <span slot="title">2020年9月</span>
                <span slot="dec">数字化订单管理，强化线上管理控制逻辑。机器人报表自动推送，实现释放人力，定时，定制化推送的功能，且相比人工准确率大大提升。</span>
            </commonLine>
            <commonLine typeIndex="2">
                <span slot="title">2020年11月</span>
                <span slot="dec">可视化实时销量跟踪系统。汉付宝上线，对金融产品等实现数字化支付。</span>
            </commonLine>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonTitle from "./components/common-title.vue";
import commonLine from "./components/common-line.vue";
export default {
  name: "",
  components: { commonTitle, commonLine },
  data() {
    return {
      curYear: 0,
      yearArr: ["2014-2017", "2018", "2019", "2020"],
    };
  },
  methods: {
    addYear() {
      if (this.curYear <= 2) {
        this.curYear += 1;
      }
    },
    reduce() {
      if (this.curYear > 0) {
        this.curYear -= 1;
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.components-wrapper {
  width: 100%;
}
.img-wrapper {
  width: 100%;
  height: 422px;
  background: url(../../assets/img/3.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  .img-box {
    width: 1200px;
    min-height: 315px;
    background-color: #ffffff;
    .dec {
      width: 1141px;
      margin: 0 auto;
      font-family: PingFang SC;
      color: #333333;
      font-size: 15px;
      line-height: 28px;
      padding-bottom: 35px;
    }
  }
}
.business-wrapper {
  width: 1200px;
  margin: 0 auto;
}
.develep-wrapper {
  width: 100%;
  height: 771px;
  padding-top: 40px;
  background: url(../../assets/img/about_company_1.png) no-repeat;
  background-size: 100% 100%;
  .change_tab {
    margin-top: 50px;
    margin-bottom: 32px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ef2147;
    font-size: 30px;
    img {
      width: 6px;
      height: 9px;
      cursor: pointer;
    }
    .img1 {
      transform: rotateY(180deg);
    }
    span {
        display: inline-block;
        width: 155px;
        text-align: center;
      margin: 0 20px;
    }
  }
  .container {
    width: 1200px;
    height: 562px;
    margin: 0 auto;
    overflow-x: auto;
    .container_box {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}
</style>
