<template>
  <div class="common-title-wrapper">
      <div class="line">
          <img src="../../../assets/img/about_title.png" alt="大大买钢">
      </div>
      <div class="name">{{title}}</div>
  </div>
</template>
<script>
export default {
  name: "",
  props:{
      title: String,
  },
  data() {
    return {};
  },
};
</script>
<style lang='scss' scoped>
.common-title-wrapper{
    width: 1200px;
    margin: 41px auto 46px auto;
    position: relative;
    .line{
       width: 1126px;
       position: absolute;
       left: 37px;
       top:5px;
       height: 20px;
       img{
           width: 100%;
           height: 100%;
       }
    }
    .name{
        width: 100%;
        text-align: center;
        font-family:PingFang SC;
        font-weight:500;
        color:#333333;
        font-size:33px;
        line-height:28px;
    }
}
</style>
