<template>
  <div class="container_box_item">
    <div class="container_box_item_1" v-if="typeIndex==1">
        <img src="../../../assets/img/about_box_tag.png" alt="大大买钢"  class="up">
        <div class="con_box con_box_1">
            <h5><slot name="title"/></h5>
            <p><slot name="dec"/></p>
        </div>
        <div class="bg_line bg_line_1">
            <span class="red"></span>
            <span class="grey" v-if="!isEnd"></span>
            <span class="grey" v-if="!isEnd"></span>
            <span class="grey" v-if="!isEnd"></span>
        </div>
    </div>
    <div class="container_box_item_2" v-if="typeIndex==2" :class="isEven?'anotherClass':''">
        <img src="../../../assets/img/about_box_tag.png" alt="大大买钢" v-if="typeIndex==2" class="down">
        <div class="con_box con_box_2">
                <h5><slot name="title"/></h5>
                <p><slot name="dec"/></p>
        </div>
        <div class="bg_line bg_line_2">
                <span class="red"></span>
                <span class="grey" v-if="!isEnd"></span>
                <span class="grey" v-if="!isEnd"></span>
                <span class="grey" v-if="!isEnd"></span>
        </div>
    </div>


  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {};
  },
  props:{
      typeIndex:{
          type:String,
          default:''
      },
      isEven:{
          type:Boolean,
          default:false
      },
      isEnd:{
          type:Boolean,
          default:false
      }
  }
};
</script>
<style lang='scss' scoped>
.container_box_item {
  width: 275px;
  height: 266px;
// width: 200px;
// height: 193px;
  background-size: 100% 100%;
  position: relative;
  margin-right: 20px;
  .anotherClass{
      position: absolute;
      left: 134px;
  }
  .con_box{
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
  }
  .con_box_1{

  }
  .con_box_2{
      top: 74px;
  }
  .bg_line {
      width: calc(50% - 22px);
      position: absolute;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .red{
          width: 26px;
          height: 26px;
          background: rgba(239, 33, 71, 1);
          border-radius: 50%;
      }
      .grey{
          width: 6px;
          height: 6px;
          background: #e1e1e1;
          border-radius: 50%;
      }
  }
  .bg_line_1{
      left: 11px;
      bottom: -6px;
  }
  .bg_line_2{
      left: 11px;
      top: -18px;
  }
  color: #333333;
  h5 {
    font-weight: normal;
    padding: 15px 20px 5px 20px;
    font-size: 18px;
  }
  p {
    padding: 0 20px;
    font-size: 15px;
    height: 137px;

    span{
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
    }
    strong{
        color: #ef2147;;
        font-weight: normal;
    }
  }
}
.up{
      width: 275px;
  height: 266px;
//    width: 200px;
// height: 193px;
//   background: url(../../../assets/img/about_box_tag.png) no-repeat;
  position: absolute;
  z-index: 0;

}
.down{
      width: 275px;
  height: 266px;
    // background: url(../../../assets/img/about_box_tag.png) no-repeat;
//     width: 200px;
// height: 193px;
    transform: rotateX(180deg);
    top:100px;
    position: relative;
    // left: -134px;
    top: -30px;
}

</style>
